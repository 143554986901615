import React from 'react'
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import { Link } from "gatsby"
import MaterialLink from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';

import PageLayout from '../../layout/page-layout';
import { GameViewModel } from '../../../gatsby/types';

interface Props {
  pathContext: {
    page: {
      siteUrl: string
      games: Array<GameViewModel>
    }
  }
}

const FacebookLinkContainer = styled.div`
  margin-top: 1em;
  display: flex;
  height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const FacebookLink = styled(MaterialLink)`
  display: flex;
  align-items: center;
  text-align: none;
`

const GatsbyLink = styled(Link)`
  text-decoration: none;
`

const MainBody = styled.main`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  overflow-x: hidden;
`

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const GameImage = styled.img`
  width: auto;
  padding-top: 1em;
  max-height: 200px
`;

const metaDescription = "Welcome Bootstrap Gamer your supply of free digital gaming aids.  Currently providing gaming aids for a limited range of games.";

export default (props: Props) => (
  <PageLayout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Bootstrap Gaming Aids</title>
      <html lang="en" />
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={`${props.pathContext.page.siteUrl}/`} />
    </Helmet>
    <AppBar position="sticky" component="header">
      <Paper square>
        <CenterContainer>
          <h1>Bootstrap Gamer Aids</h1>
        </CenterContainer>
      </Paper>
    </AppBar>
    <FacebookLinkContainer>
      <FacebookLink href="https://www.facebook.com/Bootstrap.Gamer" color="primary">
        <FacebookIcon />
        <span>Facebook</span>
      </FacebookLink>
    </FacebookLinkContainer>
    <MainBody>
      <Grid container spacing={3}>
        {props.pathContext.page.games.map((game, gameIndex) => (
          <Grid item xs={12} sm={6} md={4} key={gameIndex}>
            <div>
              <h2>
                {game.name}
              </h2>
              <GameImage src={game.image} />
              {game.aids.map((aid, aidIndex) => (
                <h3 key={`${gameIndex}-${aidIndex}`}>
                  <GatsbyLink to={aid.url}>
                    <MaterialLink color="primary" component="span">
                      {aid.name}
                    </MaterialLink>
                  </GatsbyLink>
                </h3>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </MainBody>
  </PageLayout>
)
